import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../Firebase";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import axios from "axios";
// import { sendWelcomeEmail } from "../Nodemailer/nodemailer.config";

async function saveUserToFirestore(name, email) {
  try {
    const docRef = await addDoc(collection(db, "users"), {
      name: name,
      email: email,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export function signUp(name, email, password) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      saveUserToFirestore(name, email);
      updateProfile(auth.currentUser, {
        displayName: name,
      });
      axios.post("http://localhost:8000/signup", {
        email: email,
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
}

export function logIn(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      localStorage.setItem("user", "loggedIn");
      if (user.email === "admin@wsms.com") {
        localStorage.setItem("admin", "yes");
      } else {
        localStorage.setItem("admin", "no");
      }
      window.location.href = "/home";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
}
