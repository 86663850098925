import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import cart from '../assets/shopping-cart.png'

export default function Navbar() {
  const logout = () => {
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Successfully Logged Out",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      localStorage.setItem("user", "");
      localStorage.setItem("admin", "");
      window.location.href = "/";
    }, 1500);
  };
  return (
    <div>
      <div className="w-full h-fit bg-slate-800 flex justify-between items-center px-4">
        <Link to="/home" className="text-white text-2xl py-2">
          Wholesale Management
        </Link>
        <div>
          <Link to="/cart" className="text-white">
            <img src={cart} alt="cart" height="25" width="25" className="inline" />
          </Link>
          <Link to="/admin" className="text-white px-4">
            Admin
          </Link>
          <button
            onClick={logout}
            className="bg-slate-800 text-white text-sm font-light border border-white p-2 rounded-lg cursor-pointe"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
