// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARZeTH-Wie_2QU-zaJtbwHHULNN5pN_b4",
  authDomain: "store-management-system-c56dd.firebaseapp.com",
  projectId: "store-management-system-c56dd",
  storageBucket: "store-management-system-c56dd.appspot.com",
  messagingSenderId: "536976650540",
  appId: "1:536976650540:web:14d9a8d05f1a8d6f71fc89",
  measurementId: "G-QH41Y4LW7N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
export const storage = getStorage(app);
export const storageRef = ref(storage);
export const imagesRef = ref(storage, "images");
export const db = getFirestore(app);
