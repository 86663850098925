import {
  doc,
  updateDoc,
  arrayRemove,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../Firebase";
import { getCart } from "./GetCart";

export default async function DeleteFromCart(name, price, quantity, userId) {
  let item = {
    name: name,
    price: price,
    quantity: quantity,
  };
  getCart(userId).then(async function (response) {
    let cart = response[0].cart;
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].name === name) {
        cart.splice(i, 1);
      }
    }
    const cartCollectionRef = collection(db, "cart");
    const q = query(cartCollectionRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    let cartId = "";
    querySnapshot.docs.map((doc) => (cartId = doc.id));
    const cartRef = doc(db, "cart", cartId);
    await updateDoc(cartRef, {
      cart: arrayRemove(item),
    });
    return;
  });
}
