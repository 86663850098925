import React from "react";
import "./PageNotFound.css";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div>
      <section class="page_404">
        <div class="w-full justify-center items-center">
          <div class="row flex w-full justify-center items-center">
            <div class="flex w-full justify-center items-center">
              <div class="text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center">404</h1>
                </div>

                <div class="contant_box_404">
                  <h3 class="h2">Looks like you're lost</h3>

                  <p>the page you are looking for is not available!</p>

                  <Link to="/home" class="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
