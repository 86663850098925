import React, { useState } from "react";
import "./Admin.css";
import AddProduct from "../../services/Product/AddProduct";

export default function AddProducts() {
  const [data, setData] = useState({
    name: "",
    price: "",
    description: "",
    img: null,
  });
  const submit = () => {
    AddProduct(data.name, data.price, data.description, data.img);
  };
  return (
    <div className="add-product">
      <h1 className="text-2xl"> Add Product </h1>

      <fieldset>
        <legend>
          <span className="number">1</span> Name
        </legend>
        <input
          type="text"
          id="name"
          name="product_name"
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />
      </fieldset>
      <fieldset>
        <legend>
          <span className="number">2</span> Price
        </legend>
        <input
          type="text"
          id="price"
          name="product_price"
          value={data.price}
          onChange={(e) => {
            setData({ ...data, price: e.target.value });
          }}
        />
      </fieldset>
      <fieldset>
        <legend>
          <span className="number">3</span> Description
        </legend>

        <textarea
          id="description"
          name="product_description"
          value={data.description}
          onChange={(e) => {
            setData({ ...data, description: e.target.value });
          }}
        ></textarea>
      </fieldset>
      <fieldset>
        <legend>
          <span className="number">4</span> Image
        </legend>
        <input
          type="file"
          id="img"
          name="product_img"
          onChange={(e) => {
            setData({ ...data, img: e.target.files[0] });
          }}
        />
      </fieldset>

      <button className="mt-4" onClick={submit}>
        ADD
      </button>
    </div>
  );
}
