import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Login from "./pages/LoginSignup/Login";
import Home from "./pages/Home/Home";
import Cart from "./pages/Cart/Cart";
import Admin from "./pages/Admin/Admin";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

// const userLoggedIn = localStorage.getItem("user");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/home",
    element:
      localStorage.getItem("user") === "loggedIn" ? (
        <Home />
      ) : (
        <div>Login to access</div>
      ),
  },
  {
    path: "/admin",
    element:
      localStorage.getItem("admin") === "yes" ? (
        <Admin />
      ) : (
        <div>Access Forbidden</div>
      ),
  },
  {
    path: "/cart",
    element:
      localStorage.getItem("user") === "loggedIn" ? (
        <Cart />
      ) : (
        <div>Login to access</div>
      ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
