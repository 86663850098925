import React, { useEffect, useState } from "react";
import {
  getProducts,
  getProductsByName,
  getProductsByPrice,
  searchProduct,
} from "../../services/Product/GetProducts";
import Product from "../../components/Product";

export default function AllProduct() {
  const [products, setProducts] = useState();
  useEffect(() => {
    getProducts().then(function (response) {
      setProducts(response);
    });
  }, []);

  const [searchVal, setSearchVal] = useState("");
  const search = () => {
    searchProduct(searchVal).then(function (response) {
      if (response.length > 0) setProducts(response);
      else setProducts("");
    });
  };

  const sortBy = (value) => {
    if (value === "name") {
      getProductsByName().then(function (response) {
        setProducts(response);
      });
    } else if (value === "price") {
      getProductsByPrice().then(function (response) {
        setProducts(response);
      });
    }
  };
  return (
    <>
      <div className="w-full flex justify-between items-center px-4">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="text"
              className="border border-1 text-black rounded-md h-8"
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
            />
            <button
              className="border border-1 text-black rounded-md h-8 px-2 mx-2"
              onClick={search}
            >
              SEARCH
            </button>
          </div>
          <div className="flex justify-end mx-2 mt-1">
            <div
              className="text-sm underline cursor-pointer"
              onClick={() => {
                getProducts().then(function (response) {
                  setProducts(response);
                });
              }}
            >
              RESET
            </div>
          </div>
        </div>
        <div>
          <label className="text-sm">SORT BY</label>
          <select
            name="cars"
            id="cars"
            className="h-12"
            onChange={(e) => {
              sortBy(e.target.value);
            }}
          >
            <option value="price">Price</option>
            <option value="name">Name</option>
          </select>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center">
        {products ? (
          products.map((product, key) => {
            return (
              <Product
                key={key}
                name={product.name}
                description={product.description}
                price={product.price}
                imgSrc={product.url}
              />
            );
          })
        ) : (
          <div>No Product Found</div>
        )}
      </div>
    </>
  );
}
