import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import AllProduct from "./AllProducts";
import { auth } from "../../Firebase";

export default function Home() {
  const [user, setUser] = useState();
  useEffect(() => {
    setTimeout(() => {
      setUser(auth.currentUser?.displayName);
    }, 1000);
  });
  return (
    <div>
      <Navbar />
      <div className="w-full text-center text-3xl py-4">
        Hello, {user ? user : "Loading..."}
      </div>
      {/* All product renders all products and also has sort and search function */}
      <AllProduct />
    </div>
  );
}
