import React, { useEffect, useState } from "react";
import wave from "../../assets/LoginSignup/wave.png";
import avatar from "../../assets/LoginSignup/avatar.svg";
import phone from "../../assets/LoginSignup/phone.svg";
import "./Login.css";
import { signUp } from "../../services/Auth/LoginSignup";
import { logIn } from "../../services/Auth/LoginSignup";
import Swal from "sweetalert2";

export default function Login() {
  const [login, setLogin] = useState(false);
  const viewHandler = () => {
    if (login) {
      setLogin(false);
    } else {
      setLogin(true);
    }
  };

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const submit = (e) => {
    let submitType = e.target.value;
    if (submitType === "Signup") {
      signUp(data.name, data.email, data.password);
      Swal.fire({
        position: "top",
        icon: "success",
        title: "SignUp successful",
        showConfirmButton: false,
        timer: 1500,
      });
      setLogin(true);
    } else if (submitType === "Login") {
      logIn(data.email, data.password);
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Successfully Logged In",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    const inputs = document.querySelectorAll(".login-input");

    function addcl() {
      let parent = this.parentNode.parentNode;
      parent.classList.add("focus");
    }

    function remcl() {
      let parent = this.parentNode.parentNode;
      if (this.value === "") {
        parent.classList.remove("focus");
      }
    }

    inputs.forEach((input) => {
      input.addEventListener("focus", addcl);
      input.addEventListener("blur", remcl);
    });
  });

  return (
    <div>
      <img className="wave" src={wave} alt="wave" />
      <div className="form-container">
        <div className="img">
          <img src={phone} alt="phone" />
        </div>
        <div className="login-content">
          <div className="login-form">
            <div className="flex justify-center items-center">
              <img src={avatar} alt="avatar" />
            </div>
            <h2 className="title">{login ? "Login" : "Signup"}</h2>
            {!login && (
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <h5>Username</h5>
                  <input
                    type="text"
                    className="login-input"
                    id="name"
                    name="name"
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="input-div one">
              <div className="i">
                <i className="fas fa-user"></i>
              </div>
              <div className="div">
                <h5>Email</h5>
                <input
                  type="text"
                  className="login-input"
                  id="email"
                  name="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="input-div pass">
              <div className="i">
                <i className="fas fa-lock"></i>
              </div>
              <div className="div">
                <h5>Password</h5>
                <input
                  type="password"
                  className="login-input"
                  id="password"
                  name="password"
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                />
              </div>
            </div>
            {/* <a>Forgot Password?</a> */}
            <div
              className="text-sm font-light cursor-pointer hover:text-teal-700"
              onClick={viewHandler}
            >
              {login
                ? "Don't have an acount? Signup"
                : "Have an account? Login"}
            </div>
            <input
              className="login-btn"
              value={login ? "Login" : "Signup"}
              id="login"
              name="login"
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
