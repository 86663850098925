import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";
import { getCart } from "./GetCart";
import Swal from "sweetalert2";

export async function ClearCart(userId) {
  const cartCollectionRef = collection(db, "cart");
  const q = query(cartCollectionRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  let cartId = "";
  querySnapshot.docs.map((doc) => (cartId = doc.id));
  const cartRef = doc(db, "cart", cartId);
  let emptyCart = [];
  await updateDoc(cartRef, {
    cart: emptyCart,
  });
}

export default async function Order(userId, total) {
  getCart(userId).then(async function (response) {
    let cart = response[0].cart;
    await addDoc(collection(db, "orders"), {
      cart: cart,
      userId: userId,
      total: total,
    }).then(async function () {
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Happy Shopping",
        showConfirmButton: false,
        timer: 1500,
      });
      ClearCart(userId);
    });
  });
}

export async function getOrders() {
  const ordersCollectionRef = collection(db, "orders");
  const data = await getDocs(ordersCollectionRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getOrderByOrderId(orderId) {
  const docRef = doc(db, "orders", orderId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function DeleteOrder(orderId) {
  await deleteDoc(doc(db, "orders", orderId));
}
