import { storage } from "../../Firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Firebase";

async function saveProductToFirestore(name, price, description, url) {
  try {
    const docRef = await addDoc(collection(db, "products"), {
      name: name,
      price: price,
      description: description,
      url: url,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export default function AddProduct(name, price, description, img) {
  if (img) {
    const uploadTask = uploadBytesResumable(
      ref(storage, `images/${img.name}`),
      img
    ).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        saveProductToFirestore(name, price, description, downloadURL);
      });
    });
  }
}
