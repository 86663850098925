import React, { useEffect, useState } from "react";
import { getOrders } from "../../services/Cart/Order";
import { getUserByuserId } from "../../services/Auth/User";
import { getOrderByOrderId } from "../../services/Cart/Order";
import { DeleteOrder } from "../../services/Cart/Order";
import bin from "../../assets/bin.png";

export default function Orders() {
  const [orderIds, setOrderIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [totals, setTotals] = useState([]);
  const [searchUser, setSearchUser] = useState();
  const [searchOrder, setSearchOrder] = useState();
  const [userFound, setUserFound] = useState();
  const [orderFound, setOrderFound] = useState();

  function SearchUser() {
    getUserByuserId(searchUser).then(function (response) {
      setUserFound(response);
    });
  }

  function SearchOrder() {
    getOrderByOrderId(searchOrder).then(function (response) {
      console.log(response.cart);
      setOrderFound(response);
    });
  }

  function setupOrders() {
    getOrders().then(function (response) {
      for (let i = 0; i < response.length; i++) {
        setUserIds((oldUserIds) => [...oldUserIds, response[i].userId]);
        setOrderIds((oldOrderIds) => [...oldOrderIds, response[i].id]);
        setTotals((oldTotals) => [...oldTotals, response[i].total]);
      }
    });
  }
  useEffect(() => {
    setupOrders();
  }, []);
  return (
    <div className="mb-32">
      <div className="text-4xl text-center my-4">Orders</div>
      <table className="table table-striped my-4">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">UserId</th>
            <th scope="col">OrderId</th>
            <th scope="col">Delete</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {orderIds?.map((orderId, i) => {
            return (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{userIds[i]}</td>
                <td>{orderId}</td>
                <td>
                  <img
                    src={bin}
                    alt="delete"
                    height="20"
                    width="20"
                    // onClick={DeleteOrder(orderId)}
                  />
                </td>
                <td>{totals[i]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <div className="flex flex-col w-1/3 p-4">
          <label>ADD USERID TO GET USER DETAILS</label>
          <div>
            <input
              type="text"
              className="border border-1 text-black rounded-md h-8"
              onChange={(e) => {
                setSearchUser(e.target.value);
              }}
              value={searchUser}
            />
            <button
              className="border border-1 text-black rounded-md h-8 px-2 mx-2"
              onClick={SearchUser}
            >
              SEARCH
            </button>
          </div>
          <div className="flex mt-1">
            <div
              className="text-sm underline cursor-pointer"
              onClick={() => {
                setSearchUser("");
                setUserFound("");
              }}
            >
              RESET
            </div>
          </div>
        </div>
      </div>

      {userFound ? (
        <div className="flex flex-col justify-center items-center text-2xl">
          <div>Name - {userFound.name}</div>
          <div>Email - {userFound.email}</div>
        </div>
      ) : (
        <div className="flex justify-center">NO USER TO DISPLAY</div>
      )}

      <div>
        <div className="flex flex-col w-1/3 p-4">
          <label>ADD ORDERID TO GET ORDER DETAILS</label>
          <div>
            <input
              type="text"
              className="border border-1 text-black rounded-md h-8"
              onChange={(e) => {
                setSearchOrder(e.target.value);
              }}
              value={searchOrder}
            />
            <button
              className="border border-1 text-black rounded-md h-8 px-2 mx-2"
              onClick={SearchOrder}
            >
              SEARCH
            </button>
          </div>
          <div className="flex mt-1">
            <div
              className="text-sm underline cursor-pointer"
              onClick={() => {
                setSearchOrder("");
                setOrderFound("");
              }}
            >
              RESET
            </div>
          </div>
        </div>
      </div>
      {orderFound ? (
        <div>
          <table className="table table-dark table-striped my-4">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {orderFound.cart?.map((item, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price * item.quantity}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Grand Total - {orderFound.total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center">NO ORDER TO DISPLAY</div>
      )}
    </div>
  );
}
