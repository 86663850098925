import React from "react";
import Navbar from "../../components/Navbar";
import AddProducts from "./AddProduct";
import Orders from "./Orders";
import ProductsTable from "./ProductsTable";

export default function Admin() {
  return (
    <div>
      <Navbar />
      <div className="flex">
        <AddProducts className="flex w-1/3" />
        <ProductsTable className="flex w-2/3" />
      </div>
      <Orders />
    </div>
  );
}
