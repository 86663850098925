import {
  collection,
  doc,
  addDoc,
  getDocs,
  query,
  where,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";
import { getCart } from "./GetCart";

export default async function AddToCart(userId, name, price, quantity) {
  let item = {
    name: name,
    price: price,
    quantity: quantity,
  };
  const cartCollectionRef = collection(db, "cart");
  const q = query(cartCollectionRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  // If cart already exist, push new items
  if (
    querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length > 0
  ) {
    let cartId = "";
    querySnapshot.docs.map((doc) => (cartId = doc.id));
    const cartRef = doc(db, "cart", cartId);
    getCart(userId).then(async function (response) {
      let cart = response[0].cart;
      let qty = 0;
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].name === name) {
          qty = cart[i].quantity;
          cart.splice(i, 1);
          let newQty = Number(qty) + Number(quantity);
          console.log(newQty);
          let newItem = {
            name: name,
            price: price,
            quantity: newQty,
          };
          cart.push(newItem);
        }
      }
      // If cart already has the product, update quantity
      if (qty > 0) {
        await updateDoc(cartRef, {
          cart: cart,
        });
        qty = 0;
      }
      // Else add new it as new product
      else {
        await updateDoc(cartRef, {
          cart: arrayUnion(item),
        });
      }
    });
    return;
  } else {
    // New Cart
    try {
      const docRef = await addDoc(collection(db, "cart"), {
        userId: userId,
        cart: arrayUnion(item),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
}
