import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../Firebase";

export async function getProducts() {
  const productsCollectionRef = collection(db, "products");
  const data = await getDocs(productsCollectionRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getProductsByName() {
  const productsCollectionRef = collection(db, "products");
  const q = query(productsCollectionRef, orderBy("name", "asc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function getProductsByPrice() {
  const productsCollectionRef = collection(db, "products");
  const q = query(productsCollectionRef, orderBy("price", "asc"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export async function searchProduct(searchVal) {
  const productsCollectionRef = collection(db, "products");
  const q = query(productsCollectionRef, where("name", "==", searchVal));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}
